import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import useJwt from '@/auth/jwt/useJwt'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const payload = useJwt.parseJwt() //JSON.parse(localStorage.getItem('userData'))
const existingAbility = payload ? payload?.permissions : null

export default new Ability(existingAbility || initialAbility)
