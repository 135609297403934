export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const Action = {
  Manage: 'manage',
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
}

export const Subject = {
  ADMIN: 'Admin',
  ROLE: 'Role',
  PERMISSION: 'Permission',
  USER: 'User',
  RIDER: 'Rider',
  TRIP: 'Trip',
  RIDE_TYPE: 'RideType',
  RIDE: 'Ride',
  RATE: 'PlatformRates',
  PAYMENT: 'Payment',
  COUPON: 'Coupon',
  REFERRAL: 'Referral',
  USER_REVIEW: 'UserTripReview',
  RIDER_REVIEW: 'RiderTripReview',
  DASHBOARD: 'Dashboard',
}

export const _ = undefined
