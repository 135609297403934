 const routes = {
  RIDERS: '/admin/riders',
  NEARBY_RIDERS: '/admin/trips/nearby-riders',
  RIDER_STATS: '/admin/riders/stats',
  UPDATE_SUSP_STATUS: '/admin/riders',
  UPDATE_USER_SUSP_STATUS: '/admin/customers',
  UPDATE_VERIF_STATUS: '/admin/riders',
  TRIPS: '/admin/trips',
  ONGOING: '/admin/trips/ongoing',
  SCHEDULED: '/admin/trips/scheduled',
  SET_RIDER: '/admin/trips/set-rider',
  START_TRIP: '/admin/trips/start-trip',
  END_TRIP: '/admin/trips/end-trip',
  UPDATE_EARNING: '/admin/trips/update-earning-status',
  UPDATE_EARNINGS: '/admin/trips/update-earnings-status',
  USERS: '/admin/customers',
  RIDE_TYPE: '/admin/rides/ride-types',
  BASE_RIDE_TYPE: '/admin/rides/base-ride-types',
  RATE: '/admin/trips/platform-rates',
  ADD_RATE: '/admin/trips/platform-rate',
  DELETE_RATE: '/admin/trips/platform-rate',
  EDIT_RATE: '/admin/trips/platform-rate',
  RIDER_REVIEW: '/admin/trips/rider-reviews',
  USER_REVIEW: '/admin/trips/user-reviews',
  RIDERS_ONLINE: '/admin/riders/online',
  EARNING: '/admin/trips/earnings',
  COUPON: '/admin/coupon',
  REFERRAL_RULES: '/referral/rule',
  ADMIN: '/admin',
  ADD_ADMIN: '/admin/auth/signup',
  ROLE: '/admin/role',
  PERMISSION: '/admin/permission'
}


export default routes;
