import { riderActionType, SET_ERROR } from '@/@core/utils/action-types'
import routes from '@/@core/utils/api-routes'
import useJwt from '@/auth/jwt/useJwt'

const state = {
  errors: null,
  entities: {},
  entity: null,
  stats: {},
}

const {
  FETCH_RIDERS, ADD_RIDER, EDIT_RIDER, DELETE_RIDER, RIDER_STATS,
} = riderActionType

const actions = {
  fetchReders({ commit }) {
    this.$http.get(routes.RIDERS)
      .then(({ data }) => {
        commit(FETCH_RIDERS, data)
      })
      .catch(({ response }) => {
        commit(SET_ERROR, response.data.message)
    })
  },
  fetchStats({ commit }) {
    useJwt.axiosIns.get(routes.RIDER_STATS)
      .then(({ data }) => {
        commit(RIDER_STATS, data)
      })
      .catch(({ response }) => {
        commit(SET_ERROR, response.data.message)
    })
  },
  // Ride Type
  addRideType(_, data) {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.RIDE_TYPE, data)
        .then(({ data }) => {
          console.log(data);
          resolve(data)
        })
        .catch(({ response }) => {
          console.log(response);
          reject(response?.data?.message)
        });
    });
  },
  deleteRideType(_, data) {
    return new Promise((resolve, reject) => {      
      useJwt.axiosIns.delete(routes.RIDE_TYPE+`/${data.id}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response?.data?.message)
      });
    })
  },

  // Rate
  addRate(_, data) {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.ADD_RATE+`/${data.baseType}`, data)
        .then(({ data }) => {
          console.log(data);
          resolve(data)
        })
        .catch(({ response }) => {
          console.log(response);
          reject(response?.data?.message)
        });
    });
  },
  editRate(_, data) {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.EDIT_RATE+`/${data.id}`, data)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  },
  deleteRate(_, data) {
    return new Promise((resolve, reject) => {      
      useJwt.axiosIns.delete(routes.DELETE_RATE+`/${data.id}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response?.data?.message)
      });
    })
  }
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [FETCH_RIDERS](state, data) {
    const riders = {}
    data.forEach(rider => {
      riders[rider.id] = rider
    })
    state.entities = riders
  },
  [RIDER_STATS](state, data) {
    state.stats = data
  },
  [ADD_RIDER](state, data) {
    const newRider = data
    state.entities[newRider.id] = newRider
  },
  [EDIT_RIDER](state, data) {
    const rider = data
    state.entities[rider.id] = rider
  },
  [DELETE_RIDER](state, data) {
    delete state.entities[data]
  },
}

export const rider = {
  namespaced: true,
  state,
  actions,
  mutations,
}
